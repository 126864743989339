// 123 转 一二三 
export function toChinesNum (num) {
    let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
    let unit = ["", "十", "百", "千", "万"];
    num = parseInt(num);
    let getWan = (temp) => {
        let strArr = temp.toString().split("").reverse();
        let newNum = "";
        for (var i = 0; i < strArr.length; i++) {
            newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
        }
        return newNum;
    }
    let overWan = Math.floor(num / 10000);
    let noWan = num % 10000;
    if (noWan.toString().length < 4) noWan = "0" + noWan;
    return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}
// 简单处理时间格式
export function Datetime (value, formartStr) {
	
    // 1.根据传入的毫秒创建时间对象
    if(value){
      var date = new Date(value)
      // 2.获取年月日
      var year = date.getFullYear()
      var month = date.getMonth() + 1 + ''
      var day = date.getDate() + ''
      var hours = date.getHours() + ''
      var min = date.getMinutes() + ''
      var sec = date.getSeconds() + ''
      // 3.判断需要格式化的格式
      if (formartStr && formartStr.toLowerCase() === 'yyyy-mm-dd') {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else if (formartStr && formartStr.toLowerCase() === 'yy-mm-dd') {
        return `${year}.${month.padStart(2, '0')}.${day.padStart(2, '0')} ${hours.padStart(2, '0')}:${min.padStart(2, '0')}:${sec.padStart(2, '0')}`
      } else {
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')} ${hours.padStart(2, '0')}:${min.padStart(2, '0')}:${sec.padStart(2, '0')}`
      }
    }else {
      return ''
    }
} 
// 时间戳转化 时期格式
export function GetTimeFardata(value){
	if(value){
		var date = new Date(value)
		var year = date.getFullYear()
		var month = date.getMonth() + 1 + ''
		var day = date.getDate() + ''
		return `${year}年${month.padStart(2, '0')}月${day.padStart(2, '0')}日`
	}else {
		return ''
	}
}
// 时间戳转化 星期几
export function GetDayFardata(value){
	if(value){
		var date = new Date(value)
		var DayTime = date.getDay()
		var Day = ''
		switch (DayTime) {
			case 0:
			Day = '星期天'	
			break;
			case 1:
			Day = '星期一'	
			break;
			case 2:
			Day = '星期二'	
			break;
			case 3:
			Day = '星期三'	
			break;
			case 4:
			Day = '星期四'	
			break;
			case 5:
			Day = '星期五'	
			break;
			case 6:
			Day = '星期六'	
			break;
		}
		if(new Date().getTime() === value ) {
			return Day + '（今天）'
		}else if (new Date().getTime() + 86400000 === value  ){
			return Day + '（明天）'
		}else if (new Date().getTime() + (86400000 * 2) === value  ){
			return Day + '（后天）'
		}else {
			return Day
		}
		
	}else {
		return ''
	}

}

