import 'amfe-flexible';
import App from './App';
import { router } from '@/router/router';

import * as filters from '@/filters/index'
Object.keys(filters).forEach(key=>{
    Vue.filter(key,filters[key])//插入过滤器名和对应方法
})
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
