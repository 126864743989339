const _import = require('./_import_' + process.env.NODE_ENV);
const routes = [
	{	
		path:'/',
		redirect: '/home'
	},
	{
		path:'/login',
		name: 'login',
		component: _import('login/index'),
		meta: {
			title: '登陆'
		}
	},
	{
		path:'/updatePas',
		name: 'updatePas',
		component: _import('login/updatePas'),
		meta: {
			title: '修改密码'
		}
	},
	{
		path:'/home',
		name: 'home',
		component: _import('home/index'),
		meta: {
			title: '首页'
		}
	},
	{
		path:'/laboratoryList',
		name: 'laboratoryList',
		component: _import('laboratory/laboratoryList'),
		meta: {
			title: '实验室列表'
		}
	},
	{
		path:'/laboratoryDetails/:id',
		name:'laboratoryDetails',
		component: _import('laboratory/laboratoryDetails'),
		meta: {
			title: '实验室详情'
		}
	},
	{
		path:'/makeAppointment',
		name:'makeAppointment',
		component: _import('makeAppointment/index'),
		meta: {
			title: '实验室预约'
		},
		children:[
			{
				path:'laboratoryInfo',
				name:'laboratoryInfo',
				component: _import('makeAppointment/laboratoryInfo'),
				meta: {
					title: '实验室预约'
				},
			},
			{
				path:'laboratoryInstrument',
				name:'laboratoryInstrument',
				component: _import('makeAppointment/laboratoryInstrument'),
				meta: {
					title: '实验室预约'
				},
			},
			{
				path:'laboratoryReagent',
				name:'laboratoryReagent',
				component: _import('makeAppointment/laboratoryReagent'),
				meta: {
					title: '实验室预约'
				},
			},
			{
				path:'laboratoryProtect',
				name:'laboratoryProtect',
				component: _import('makeAppointment/laboratoryProtect'),
				meta: {
					title: '实验室预约'
				},
			}
		]
	},
	{
		path:'/user',
		name:'user',
		component: _import('user/index'),
		children:[
			{
				path:'userInfo',
				name:'userInfo',
				component: _import('user/userInfo'),
				meta: {
					title: '个人信息'
				}
			},
			{
				path:'updataPsswrod',
				name:'updataPsswrod',
				component: _import('user/updataPsswrod'),
				meta: {
					title: '修改密码'
				}
			},
		]
	},
	{
		path:'/queryMake',
		name:'queryMake',
		component: _import('queryMake/index'),
		meta: {
			title: '预约查询'
		}
	},
	{
		path:'/makeDetails/:id',
		name:'makeDetails',
		component: _import('queryMake/makeDetails'),
		meta: {
			title: '预约详情'
		}
	},
	{
		path:'/danger',
		name:'danger',
		component: _import('danger/index'),
		meta: {
			title: '危险品实验申请'
		}
	},
	{
		path:'/dangerAdd',
		name:'danger',
		component: _import('danger/dangerAdd'),
		meta: {
			title: '危险品实验申请'
		}
	},
	{
		path:'/dangerEdit/:id',
		name:'dangerEdit',
		component: _import('danger/dangerEdit'),
		meta: {
			title: '危险品实验详情'
		}
	},

];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new VueRouter({  mode:'history', routes });
const whiteList = ['/login','/updatePas'] 

router.beforeEach((to, from, next) => {
	const title = to.meta && to.meta.title;
	if (title) {
		document.title = title;
	}
  	if(localStorage.getItem('Token')){
		if(whiteList.indexOf(to.path) > -1){
			next({path:'/',replace:true})
		}else {
			next()	
		}
  	}else {
		if (whiteList.indexOf(to.path) > -1) {
			next();
		} else {
			next('/login')
		}
  	}
//   next();
});

export {
  router
};
